<template>

  <div class="row align-items-center option-section">
    <label for="optional_elem" style="width:100%">
      <div class="col-12 text-left text-dark container-optional">
      <div class="d-inline-block font-weight-bold">
        <div class="check-content d-inline-block">
        <input id="optional_elem" type="checkbox" class="checkbox-round" v-model="isChecked" @change="handleChange">
        </div>
        <span> {{ option_price.name }}</span>
      </div>
      <div class="font-weight-bold padding-left">
        <div>{{ option_price | formatAmount }}</div>
      </div>
      <div class="font-weight-normal price-description padding-left">
        {{ $t(option_price.meta_data.description) }}
      </div>
    </div>
    </label>
  </div>
</template>

<style>
        .container-optional {
            padding: 8px 16px 8px 8px;
            border-radius: 16px;
            border: 1px solid rgb(209, 213, 219);
        }
        .container-optional:has(input[type="checkbox"]:checked) {
              background: rgb(221, 221, 253);
        }
        .check-content {
            padding: 0rem 0.2rem;
        }
        .padding-left {
            padding-left: 2rem;
        }

        .option-section {
            padding: 0.25rem 1rem 1rem;
        }

        .checkbox-round {
            width: 1.3rem;
            height: 1.3rem;
            background-color: transparent;
            border-radius: 50%;
            vertical-align: middle;
            border: 1px solid #333;
            appearance: none;
            -webkit-appearance: none;
            outline: none;
            cursor: pointer;
            position: relative;
        }

        .checkbox-round:checked {
            background-color: transparent;
        }

        .checkbox-round:checked::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            width: 0.65rem;
            height: 0.65rem;
            background-color: gray;
            border-radius: 50%;
            transform: translate(-50%, -50%);
        }

        .price-description {
          font-size: 0.8em;

          @media (min-width: 768px) {
            font-size: 1em;
          }
        }

</style>

<script setup>
import { mapActions } from "vuex";

export default {
  name: "SubscriptionOptionButton",
  data() {
    return {
      isChecked: false
    };
  },
  props: {
    option_price: {
      type: Object,
      required: false,
    },
  },

  onMounted() {
    console.log("option_price", this.option_price);

  },
  methods: {
    ...mapActions("subscriptions", ["addOptionToSelectedOptions", "removeOptionToSelectedOptions"]),

    handleChange(event) {
      console.log("checked", this.isChecked);
      let price = this.$props.option_price;
      if (this.isChecked) {
        console.log("add elem price ", price);
        this.addOptionToSelectedOptions(price);
      } else {
        this.removeOptionToSelectedOptions(price);
      }

    }
  }
}
</script>
<template>
    <div class="text-remaining">
        <div v-if="isSDC" class="text-center" v-bind:class=[textStateSDC]>
            {{ messageSDC }}
        </div>
        <div v-else class="text-center" v-bind:class=[textStateLegacy]>
            {{ messageRemainingLegacy }}
        </div>
    </div>
</template>

<script>
export default {
    name: "SubscriptionRemainingDays",
    props: {
        tracker: null,
        recurring: null,
    },

    computed: {
        //Chek if a tracker is selected
        isTrackerSelected: function () {
            return this.tracker !== null;
        },

        //Check if the subscription is ended
        isSubscriptionEnded: function () {
            return this.isTrackerSelected && this.tracker.tracker_status.sub_end_date && (new Date(this.tracker.tracker_status.sub_end_date) < new Date());
        },

        isSDC: function () {
            return this.tracker.type == "tracker_04" || this.tracker.type == "tracker_06";
        },

        //Compute how many days left
        remainingDays: function () {
            var DAYINMILLISECOND = 1000 * 3600 * 24;

            if (this.isTrackerSelected && this.tracker.tracker_status.sub_end_date) {
                var subDaysLeft = (new Date(this.tracker.tracker_status.sub_end_date).getTime() - new Date().getTime()) / DAYINMILLISECOND
                return Math.round(subDaysLeft)
            }
            else {
                return 0
            }
        },

        //Return the right message to display
        messageRemainingLegacy: function () {
            if (this.tracker.tracker_status.sub_state == "not_activated") {
              return app.$t('subscription_must_be_activated');
            } else if (!this.remainingDays){
              return ''
            } else if (this.recurring && this.recurring.status == 'active') {
              return app.$t('subscription_renewal') + " " + this.remainingDays + " " + app.$t('days');
            } else if (this.remainingDays < 30) {
              if (this.isSubscriptionEnded) {
                return app.$t('subscription_ended');
              } else {
                let msg = app.$t('subscription_timeleft') + " " + this.remainingDays + " " + app.$t('days');
                if (!this.recurring) {
                  msg += ", " + app.$t('renew_it_time');
                }
                return msg;
              }
            } else {
                return app.$t('subscription_timeleft') + " " + this.remainingDays + " " + app.$t('days')
            }
        },

        messageSDC: function () {
            if (this.tracker.tracker_status.sub_state == "not_activated") {
                return app.$t('subscription_collar_not_activated')
            } else {
                return app.$t('subscription_collar_until') + this.tracker.tracker_status.sub_end_date
            }
        },

      textStateLegacy: function () {
        if (this.recurring) {
          return this.remainingDays ? "text-success" : "text-subscription-danger";
        }
        return this.remainingDays < 30 ? "text-subscription-danger" : "text-success";
      },

      textStateSDC: function () {
        return this.remainingDays ? "text-sdc-sucess" : "text-sdc-danger";
      }
    },
}
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
.text-sdc-danger {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;

    color: #E8271A;
}

.text-sdc-sucess {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;

    text-align: center;

    color: #1D60E0;
}

.text-remaining {
    max-width: 100vw;
    height: 50px;
    margin: 10px;
}

.text-subscription-danger {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;

    text-align: center;

    color: #E8271A;
}
</style>
<template>
    <b-badge v-if="rebate" v-bind:class="{'badge-warning': rebate==20}" class="px-2 text-white" pill>- {{rebate}}%</b-badge>
</template>

<script>
export default {
    name: 'RebateBadge',
    props: {
        rebate: null,
    },
}
</script>

<style scoped>

</style>
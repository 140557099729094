<template>
  <div class="row h-100 align-items-center subscriptions-container">
    <div class="col-12 px-4">
      <div class="text-center my-3 h4 font-weight-bold">
        {{ subscriptionMessage}}
      </div>
      <div v-if="!isSDC&&!recurringActiveSubscription&&noSubscriptions" class="text-center h5 my-2">
        {{ $t("no_subscriptions") }}
      </div>
      <SubscriptionRemainingDays :tracker="getSelectedDevice" :recurring="recurringActiveSubscription"/>

      <SubscriptionPriceButton
        v-for="subscription in realSubscriptionPricesForDevice"
        :key="subscription.id"
        :trackersubscription="subscription"
        :isMonthly="checkSubscriptionsMonthly"
      />
      <SubscriptionOptionButton
        v-for="price in optionPricesForDevice"
        :key="price.id"
        :option_price="price"></SubscriptionOptionButton>
      <SubscriptionCheckOutButton v-if="!noSubscriptions" />
      <div v-else-if="recurringActiveSubscription">

        <CustomerPortalButton :tracker="getSelectedDevice"/>
      </div>

      <div v-if="isSDC && !realSubscriptionPricesForDevice && getSelectedDevice.tracker_status.sub_state != 'normal'">
      <a :href="LinkToSubscriptionPage">
        <div class="btn sub-button d-block  subscription-button" block variant="primary">
          <div class="white">
            {{ $t("choose_subscription") }}
          </div>
        </div>
      </a>

      <router-link to="/link_subscription">
        <button class="btn sub-button btn-block light-subscription-button" block variant="primary">
          <div class="text-black">
            {{ $t("link_subscription") }}
          </div>
        </button>
      </router-link>
      </div>
      <div v-if="showSubscriptionPriceMessage">
        <a href="/MessageSubscriptionPrices" target="_blank"><div class="text-prices mt-3 text-center" target="_blank">{{ $t("subscription_price_increase_message_link") }}</div></a>
      </div>
      <CguLink />
    </div>
  </div>
</template>

<script>
import SubscriptionRemainingDays from "@/components/subscription/SubscriptionRemainingDays";
import SubscriptionPriceButton from "@/components/subscription/SubscriptionPriceButton";
import SubscriptionCheckOutButton from "@/components/subscription/SubscriptionCheckOutButton";
import CustomerPortalButton from "@/components/subscription/CustomerPortalButton.vue";
import CguLink from "@/components/ui/CguLink";
import SubscriptionOptionButton  from "./SubscriptionOptionButton.vue";

import { mapActions, mapGetters, mapMutations } from "vuex";

import {detectBrowserCountry, redirectLocaleWeb} from "../../international";

export default {
  name: "SubscriptionSelector",
  components: {
    SubscriptionRemainingDays,
    SubscriptionPriceButton,
    SubscriptionCheckOutButton,
    SubscriptionOptionButton,
    CustomerPortalButton,
    CguLink,
  },
  data() {
    return {
      reason: null,
      stripe: null,
    };
  },

  methods: {
    ...mapActions("subscriptions", ["setSelectedSubscription"]),
    ...mapActions("devices", ["setSelectedDevice"]),
  },

  computed: {
    ...mapGetters("devices", ["getSelectedDevice"]),
    ...mapGetters("users", ["getUserName"]),
    ...mapGetters("subscriptions", [
      "getSubscriptions",
      "getSelectedSubscription",
      "getLoading",
    ]),
    subscriptionMessage() {
      let state = this.getSelectedDevice.tracker_status.sub_state;
      if (state == 'not_activated') {
        return this.$i18n.t('choose_subscription')
      } else if (state == 'normal') {
        if (this.recurringActiveSubscription){
          return this.$i18n.t("Subscription_type")
        }
        return this.$i18n.t("extend_your_subscription")
      } else {
        return this.$i18n.t("renew_your_subscription")
      }
    },
    showSubscriptionPriceMessage() {
      return false
    },
    checkSubscriptionsMonthly() {
      let isMonthly = false;
      this.subscriptionPricesForDevice.forEach(subscription => {
        if (parseInt(subscription.meta_data.months) < 12) {
          isMonthly = true;
        }
      });
      return isMonthly
    },
    noSubscriptions() {
      //console.log("no subscription ?", this.getSubscriptionsByDeviceType);
      if (
        this.subscriptionPricesForDevice &&
        this.subscriptionPricesForDevice.length
      ) {
        return false;
      } else {
        return true;
      }
    },
    isSDC() {
       let device = this.getSelectedDevice;
       return device.type == "tracker_04" || device.type == "tracker_06";
    },
    LinkToSubscriptionPage() {
      if (!this.getUserName ){
        return null;
      }
      return 'https://petcare.invoxia.com/' + this.redirectCountryLanguage + '/subscription' + '?email=' + encodeURIComponent(this.getUserName)
    },
    redirectCountryLanguage() {
      return redirectLocaleWeb()
    },
    recurringActiveSubscription(){
      let subscriptionForDevice = this.getSubscriptions.find(
          (subscriptionByTrackers) => {
            return subscriptionByTrackers.serial == this.getSelectedDevice.serial;
          }
      );
      if (!subscriptionForDevice) {
        return null;
      }
      if (subscriptionForDevice.current) {
        return subscriptionForDevice.current;
      }
      if (subscriptionForDevice.current_subscription) {
        return subscriptionForDevice.current_subscription
      }
      return null
    },
    subscriptionPricesForDevice() {
      let subscriptionsByDeviceType = this.getSubscriptions.find(
        (subscriptionByTrackers) => {
          return subscriptionByTrackers.serial == this.getSelectedDevice.serial;
        }
      );
      if (subscriptionsByDeviceType) {
       // console.log("subscriptionsByDeviceType", subscriptionsByDeviceType);
        let activeSubscription = subscriptionsByDeviceType.current_subscription;
        if (activeSubscription) {
          return null;
        }
        var subscriptionPrices = subscriptionsByDeviceType.prices;
        subscriptionPrices.sort(function (a, b) {
          if (parseInt(a.meta_data.months) > parseInt(b.meta_data.months)) {
            return 1;
          } else if (parseInt(a.meta_data.months) < parseInt(b.meta_data.months)) {
            return -1;
          }
          return 0;
        });
        this.setSelectedSubscription(subscriptionPrices[0]);
        return subscriptionPrices;
      } else {
        this.setSelectedSubscription(null);
        return null;
      }
    },
    realSubscriptionPricesForDevice(){
      let sub_list = this.subscriptionPricesForDevice;
      if (!sub_list){
        return null;
      }
      let sub2_list = sub_list.filter(obj => obj.meta_data && obj.meta_data.network_sub === 'true');
      if (sub2_list.length > 0){
        this.setSelectedSubscription(sub2_list[0]);
      } else {
        this.setSelectedSubscription(null);
      }
      return sub2_list
    },
    optionPricesForDevice(){
      let sub_list = this.subscriptionPricesForDevice;
      if (!sub_list){
        return null;
      }
      return sub_list.filter(obj => obj.meta_data && obj.meta_data.network_option === 'true');
    }
  },
  mounted() {
  // let country = detectBrowserCountry();
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.subscriptions-container {
  overflow: scroll;
    width: auto;

}

.text-prices{
  color: #000;
  text-decoration: underline;
  cursor: pointer;
}

.light-subscription-button {
  margin-top: 1em;
  margin-bottom: 1em;

  border-radius: 18px;
  padding: 18px;


  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  /* Color-primary/200 */

  background: #F1F8FD;
  /* Color-neutral-shades/200 */

  border: 2px solid #F0F0F0;
  border-radius: 18px;

  color: #3B3B3B;
}

.subscription-button {
  background: radial-gradient(135.75% 139.94% at 0% 50%, #418FDE 0%, #1D60E0 43.64%);
  border-color: transparent;
  border-radius: 18px;
  padding: 18px;
  box-shadow: 0px 5px #174396;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;

  color: #F1F8FD;
}

</style>
<template>
  <b-button class="portal-button mx-4" block variant="primary"
            v-on:click="goToStripePortal()">
    <div class="text-white">
      {{ $t("payment_portal_link") }}
    </div>
  </b-button>
</template>

<script>
import {getStripePortal} from "../../api/devices.api";

export default {
  name: 'CustomerPortalButton',
  props: {
    tracker: null
  },
  mounted(){
    console.log("CustomerPortalButton " + this.tracker)
  },
  methods: {
    async goToStripePortal() {
      const current_route = this.$router.currentRoute;
      const portalUrl = await getStripePortal(current_route.path + '?serial=' + this.tracker.serial);
      window.location.href = portalUrl.data;
    }
  },
}
</script>

<style scoped>

.portal-button {
  background: radial-gradient(135.75% 139.94% at 0% 50%, #418FDE 0%, #1D60E0 43.64%);
  border-color: transparent;
  border-radius: 18px;
  padding: 18px;
  box-shadow: 0px 5px #174396;

  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  color: #F1F8FD;
}
</style>
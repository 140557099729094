const ct = require('countries-and-timezones');

export function detectBrowserCountry() {
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const country = ct.getCountryForTimezone(timezone);
    console.log("country", country);
    return country.id;
}

export function redirectLocaleWeb() {
    const country = detectBrowserCountry();
    var {region} = new Intl.Locale(navigator.language);

    var locale = navigator.language;

    if (region != country) {
        region = country;
        const lang = navigator.language.substring(0, 2)
        locale = lang + '-' + country;
        console.log("Mismatch locale and timezone: move to '" + lang + "' + '"+ country + "'");
    }

    if (country == 'FR' || locale == 'fr-FR') {
        return 'fr'
    } else if (locale == 'en-US') {
        return 'en-US'
    } else if (locale == 'es-US') {
        return 'es-US'
    } else if (country == 'DE' || locale == 'de-DE') {
        return 'de'
    } else if (locale == 'de-CH') {
        return 'de-CH'
    } else if (country == 'CH' || locale == 'fr-CH') {
        return 'fr-CH'
    } else if (country == 'BE' || locale == 'fr-BE') {
        return 'fr-BE'
    } else if (country == 'DK' || locale == 'da-DK') {
        return 'da-DK'
    } else if (country == 'NL' || locale == 'nl-NL') {
        return 'nl-NL'
    } else if (country == 'ES' || locale == 'es' || locale == 'es-ES') {
        return 'es-ES'
    } else if (country == 'SE' || locale == 'sv-SE') {
        return 'sv-SE'
    } else if (country == 'FI' || locale == 'fi-FI') {
        return 'fi-FI'
    } else {
        return 'fr'
    }
}
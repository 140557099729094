<template>
    <b-badge variant='warning' v-if="gift" class="px-2 text-white" pill>{{ $t('extra_gift_label', {duration: this.$options.filters.formatSubscriptionDuration(gift) })}}</b-badge>
</template>


<script>
export default {
    name: 'GifgtBadge',
    props: {
        gift: null,
    },
}
</script>

<style scoped>

</style>
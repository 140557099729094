<template>
  <b-button
    v-on:click="setSelectedSubscription(trackersubscription)"
    class="btn-price"
    block
    variant="outline-secondary my-3"
    v-bind:class="{
      'btn-price-active':
        getSelectedSubscription &&
        getSelectedSubscription.id == trackersubscription.id,
    }"
  >
    <div class="row align-items-center">
      <div class="col-9 col-md-10 text-left text-dark text-subscription">
        <div class="d-block font-weight-bold">
          {{
            trackersubscription.meta_data.months | formatSubscriptionDuration
          }}
          <span v-if="trackersubscription.meta_data.extra">
            + {{ trackersubscription.meta_data.extra | formatSubscriptionDuration }}
          </span>
          {{ $t("subscription") }} {{ $t("for") }}
          {{ trackersubscription | formatSubscriptionTotalPrice }}
        </div>
        <div class="d-block font-weight-normal price-description">
          <div v-if="trackersubscription.meta_data.recurring">
            {{ $t("paymentWithRenewal", { price: formatSubscription(trackersubscription), }) }}
          </div>
          <div v-else>
            {{ $t("oneTimePaymentNoRenewal", { price: formatSubscription(trackersubscription), }) }}
          </div>
        </div>
      </div>
      <div class="col-3 col-md-2 text-right price-details">
        <div
          v-if="trackersubscription.meta_data.extra"
          class="d-block gift-area"
        >
          <GiftBadge :gift="trackersubscription.meta_data.extra" />
        </div>
        <div
          v-else-if="trackersubscription.meta_data.rebate"
          class="d-block rebate-area"
        >
          <RebateBadge :rebate="trackersubscription.meta_data.rebate" />
        </div>
        <div class="d-block">
          {{ switchFormatSubscription(trackersubscription) }}
          <p class="price-details-unit">{{ isMonthly ? $t("per_month") : $t("per_year") }}</p>
        </div>
      </div>
    </div>
  </b-button>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import RebateBadge from "@/components/ui/RebateBadge";
import GiftBadge from "@/components/ui/GiftBadge";

export default {
  name: "SubscriptionPriceButton",
  components: {
    RebateBadge,
    GiftBadge,
  },
  props: {
    trackersubscription: {},
    isMonthly: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters("subscriptions", ["getSelectedSubscription"]),
  },

  methods: {
    ...mapActions("subscriptions", ["setSelectedSubscription"]),
    formatSubscription(subscription) {
      return this.$options.filters.formatSubscriptionTotalPrice(subscription);
    },
    switchFormatSubscription(subscription) {
      if (this.isMonthly) {
        return this.$options.filters.formatSubscriptionMonthlyPrice(
          subscription
        );
      } else {
        return this.$options.filters.formatSubscriptionYearlyPrice(
          subscription
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-price {
  border-width: 1.5px;
  border-radius: 10px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 0.3rem 2rem;
}
.btn-price:hover {
  color: #6c757d;
  border: 1px solid #2097f7;
  background-color: white;
  box-shadow: none;
}

.btn-price:active,
.btn-price-active {
  color: #6c757d !important;
  border: 1px solid #2097f7 !important;
  background-color: white !important;
  box-shadow: #007bff !important;
}

.btn-price:focus {
  color: #6c757d;
  border: 1px solid #2097f7;
  background-color: white;
  box-shadow: none !important;
}

.text-subscription {
  padding: 1em 0em;

  @media (min-width: 768px) {
    padding: 1em 0.5em;
  }
}

.price-description {
  font-size: 0.8em;

  @media (min-width: 768px) {
    font-size: 1em;
  }
}

.price-details, .price-details-unit {
  font-size: 0.8em;
  margin-bottom: 0;
  padding: 0;

  @media (min-width: 768px) {
    font-size: 1em;
  }
}

.rebate-area {
  min-height: 25px;
}

.gift-area {
  min-height: 25px;
}
</style>